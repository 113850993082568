'use strict';

import 'babel-polyfill';
import './polyfill/foreach';
import './polyfill/closest';

import config from './config'
import Pages from './pages'

class App {

    constructor(page) {
        const _ = this
        _.page = page;

        // Window load function
        var load = () => {
            config.$body.classList.add('is-loaded')
            // Add browser class to html element
            if(config.browserName) {
                config.$html.classList.add(`is-${config.browserName}`)
            }
            if(config.deviceOS) {
                config.$html.classList.add(`is-${config.deviceOS}`)
            }
        }

        // Window scroll function
        var scroll = () => {
        }

        // Window resize function
        var resize = () => {
            config.W = {
                w : window.innerWidth,
                h : window.innerHeight
            }
        }

        // Document load
        document.addEventListener('DOMContentLoaded', load)

        // Window events
        window.addEventListener('resize', resize)
        window.addEventListener('scroll', scroll)

        // IE matches fix
        // if (!Element.prototype.matches) {
        //     Element.prototype.matches = Element.prototype.msMatchesSelector;
        // }

        // Init page
        if (_.page) _.init(_.page);
    }

    init(page) {
        const _ = this
        _.page = page;
        _.page = new Pages[_.page]();
    }

};

window.app = new App();
