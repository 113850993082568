'use strict';

import $ from 'jquery'
import config from '../config'

const updateSizes = Symbol('updateSizes');
const throttle = Symbol('throttle');

export default class Lazy {

    constructor(options){
        const _ = this

        _.opts = {
            src: 'data-src',
            selector: '.js-lazy',
            separator: ' | ',
            breakpoints: [
                {
                    width : 1440,
                    src   : 'data-src-largest'
                },
                {
                    width : 1280,
                    src   : 'data-src-larger'
                },
                {
                    width : 992,
                    src   : 'data-src-large'
                },
                {
                    width : 768,
                    src   : 'data-src-medium'
                },
                {
                    width : 480,
                    src   : 'data-src-small'
                },
                {
                    width : 0,
                    src   : 'data-src-smaller'
                }
            ],
            offset: window.innerWidth,
            onError: function() {},
            errorClass: 'has-error',
            onSuccess: function() {},
            successClass: 'is-loaded'
        };

        _.setOpts(options);


        _.destroyed = true;
        _.$images = $(_.opts.selector);
        _.imagesArray = [];
        // Push images to the images array
        _.$images.each(function(i, el) {
            _.imagesArray.push(this);
        });

        _[updateSizes]();

        // Create throttle functions
        _.validateT = _[throttle](_.validate.bind(_), 250);
        _.updateSizesT = _[throttle](_[updateSizes].bind(_), 500);

        _.updateAndValidateT = function() {
            _.updateSizesT();
            _.validateT();
        };

        // Use the right source based on viewport width
        $.each(_.opts.breakpoints, function(i, el) {
            if (el.width <= _.viewWidth) {
                _.source = el.src;
                return false;
            }
        });

        // Then we bind resize and scroll events if not already binded
        if (_.destroyed) {
            _.destroyed = false;
            $(window).on({
                resize: _.updateAndValidateT,
                scroll: _.validateT
            });
        }
        // And finally, we start to lazy load. Should bLazy ensure domready?
        _.validate();
    }

    getOpts() {
        console.log(_.opts);
        return _.opts;
    }

    setOpts(options) {
        const _ = this
        $.extend(true, _.opts, options);
    }

    /**
     * Validate if an image is in view
     * @return {undefined}
     */
    validate() {
        const _ = this

        _.$images.each(function(i, el) {
            var $this = $(this);
            if (_.isInViewport(this) || $this.hasClass(_.opts.successClass)) {
                _.load(this);
            }
        });

        if (_.imagesArray.length === 0) {
            _.destroy();
            return false;
        }
    };


    /**
     * Load an image
     * @param  {object} el The image to load
     * @return {undefined}
     */
    load(el) {
        const _ = this

        var $el = $(el);
        // Get the correct attribute
        var dataSrc = $el.attr(_.source) || $el.attr(_.opts.src);

        // Return if element is not visible or has no data-src
        if (!dataSrc) return;

        var dataSrcSplitted = dataSrc.split(_.opts.separator);
        var src = dataSrcSplitted[config.isRetina && dataSrcSplitted.length > 1 ? 1 : 0];

        // cleanup markup, remove data source attributes
        $.each(_.opts.breakpoints, function(i, el) {
            $el.removeAttr(el.src);
        });
        $el.removeAttr(_.opts.src);

        // Image
        if (el.nodeName.toLowerCase() === 'img' || el.src === undefined) {
            // Here the fun begins
            var img = new Image();

            img.onerror = function() {
                if (_.opts.onError) _.opts.onError(el, 'invalid');
                $el.addClass(_.opts.errorClass);
            };

            img.onload = function() {
                // Is element an image or should we add the src as a background image?
                if (el.nodeName.toLowerCase() === 'img') {
                    el.src = src;
                } else if (el.nodeName.toLowerCase() === 'image') {
                    var newEl = document.createElementNS('http://www.w3.org/2000/svg', 'image');
                    newEl.setAttributeNS('https://www.w3.org/1999/xlink', 'xlink:href', src);
                    newEl.setAttribute('x', el.getAttribute('x'));
                    newEl.setAttribute('y', el.getAttribute('y'));
                    newEl.setAttribute('width', el.width.baseVal.value);
                    newEl.setAttribute('height', el.height.baseVal.value);
                    el.parentNode.appendChild(newEl);
                    el.remove();
                } else {
                    $el.css('background-image', 'url("' + src + '")');
                }

                $el.addClass(_.opts.successClass);
                if (_.opts.onSuccess) _.opts.onSuccess(el);



            };
            img.src = src; // preload image

            // Remove image from array when loaded
            _.imagesArray.splice($.inArray(el, _.imagesArray), 1);

        } else if (el.nodeName.toLowerCase() === 'video') {
            el.src = src;
            $el.addClass(_.opts.successClass);
            if (_.opts.onSuccess) _.opts.onSuccess(el);
        }

    };


    /**
     * Check if an element is in the viewport
     * @param  {object} el The element to test
     * @return {boolean}   If the element is in viewport or not
     */
    isInViewport(el) {
        const _ = this
        var pos = el.getBoundingClientRect();
        var bottomline = _.viewHeight + _.opts.offset;
        return pos.top >= 0 && pos.top  <= bottomline || pos.bottom <= bottomline && pos.bottom >= 0 - _.opts.offset;
    };


    /**
     * Revalidate lazyload
     * @return {undefined}
     */
    revalidate() {
        const _ = this
        if (!_.destroyed) _.validate();
    }

    /**
     * Re-init the lazyload with new elements
     * @return {undefined}
     */
    reInit() {
        const _ = this

        // Update the images object with the new images, but not the one alreayd loaded
        _.$images = $(_.options.selector).not('.'+_.options.successClass);

        // If we have images, revalidate
        if (_.$images) {
            _.destroyed = false;
            _.revalidate();
        }
    }

    /**
     * Destroy Lazy, unbind events
     * @return {undefined}
     */
    destroy() {
        const _ = this
        _.destroyed = true;

        $(window).off({
            resize: _.updateAndValidateT,
            scroll: _.validateT
        });
        _.$images = null;
    }

    /* ================================
     * Private methods
     * ================================ */

    /**
     * Private vars update
     * @return {undefined}
     */
    [updateSizes]() {
        const _ = this
        _.viewWidth = window.innerWidth || document.documentElement.clientWidth;
        _.viewHeight = window.innerHeight || document.documentElement.clientHeight;
    }

    /**
     * Private throttle function
     * @param  {function} fn       The function to call
     * @param  {number}   minDelay The throttle delay
     * @return {function}          A function which fires every {minDelay}ms
     */
    [throttle](fn, minDelay) {
        var lastCall = 0;
        return function() {
            var now = +new Date();
            if (now - lastCall < minDelay) {
                return;
            }
            lastCall = now;
            fn();
        };
    }
};
