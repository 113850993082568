import config from '../config';
import Default from './default'

import Slider from '../components/slider'
import MainVideo from '../components/main-video'

export default class Gallery extends Default {

    constructor() {
        super()
        const _ = this;

        // _.sliders.push(new Slider('.js-slider-fullscreen', {
        //     counter: true,
        //     flickity: {
        //         pageDots: false
        //     }
        // }))

        _.sliders.push(new Slider('.js-slider', {
            counter: true,
            // expand: _.sliders[0],
            flickity: {
                pageDots: false,
                wrapAround: true
            }
        }))

        document.querySelector('.js-slider .c-slider__caption').addEventListener('click', function(e){
            e.stopPropagation()
        })

        new MainVideo
    }
}
