import config from '../config';
import Default from './default'

export default class Terms extends Default {

    constructor() {
        super()
        const _ = this;

        const $tables = document.querySelectorAll('.js-table')

        $tables.forEach(function($table, index){
            // Get all rows
            let $rows = $table.querySelectorAll('tr')
            // Get total cols
            let totalCols = $rows[0].childElementCount
            // Col counter
            let colCount = 0

            // Iterate cols
            while (colCount < totalCols) {
                let $originalCell = false

                // Iterate rows
                $rows.forEach(function(row, rowCount){

                    // Return if first row
                    if (rowCount == 0) return;

                    // Get prev row
                    let $prevCell = $rows[rowCount - 1].children[colCount]
                    let $currentCell = row.children[colCount]

                    // If prev value is equal to actual value
                    if ($prevCell.innerHTML == $currentCell.innerHTML) {
                        // If don't exist original cell
                        if (!$originalCell) {
                            // Save original cell
                            $originalCell = $prevCell
                        }
                        // Get rowspan value
                        let rowspanValue = !$originalCell.getAttribute('rowspan') ? 1 : parseInt($originalCell.getAttribute('rowspan'), 10)

                        // Update original cell to new rowspan value
                        $originalCell.setAttribute('rowspan', rowspanValue + 1)

                        // Hidden actual cell
                        $currentCell.style.display = 'none'
                    } else {
                        $originalCell = false
                    }
                })
                colCount++
            }
        })
    }
}
