import gsap from 'gsap'
import scrollTo from 'gsap/ScrollToPlugin';


export default class Accordion {

    constructor(selector, options){
        // console.log('Accordion:constructor');
        const _ = this;

        _.opts = {
            scroll: false,
            onStart: function() {},
            onComplete: function() {},
            onOpen: function() {},
            onClose: function() {},
            onUpdate: function() {}
        };

        _.$parent = selector;
        _.$trigger = _.$parent.querySelector('.js-accordion-trigger');
        _.$container = _.$parent.querySelector('.js-accordion-container');
        _.$content = _.$parent.querySelector('.js-accordion-content');

        _.isOpen = false

        _.setOpts(options);

        if(_.$trigger) {
            _.$trigger.addEventListener('click', function(e) {
                _.toggle(e)
            })
            console.log(_.$trigger)
        }

        const target = _.$parent.dataset.target
        if(target) {
            _.$outerTrigger = document.querySelector(`.js-accordion-trigger[data-target="${target}"]`)

            _.$outerTrigger.addEventListener('click', function(e) {
                _.toggle(e)
            })
        }
    }

    getOpts() {
        const _ = this;
        // console.log('Accordion:getOpts');
        return _.opts;
    }

    setOpts(options) {
        const _ = this;
        // console.log('Accordion:setOpts');
        _.opts = Object.assign({}, _.opts, options);
    }

    toggle(e) {
        const _ = this;
        // console.log('Accordion:toggle');
        e.preventDefault();

        _.isOpen = !_.isOpen

        let height = _.isOpen ? _.$content.offsetHeight: 0;
        let opacity = _.isOpen ? 1 : 0;

        // Toggle state
        _.$parent.classList.toggle('is-open');

        // Launch function onStart
        _.opts.onStart(_.$parent, _.isOpen);

        // Launch open/close function
        if(_.isOpen) {
            _.opts.onOpen(_.$parent);
        } else {
            _.opts.onClose(_.$parent);
        }

        TweenLite.to(_.$container, 1, {
            height: height,
            opacity: opacity,
            onComplete: () => {
                TweenLite.set(_.$container, { height: _.isOpen ? 'auto' : 0 })
                // Launch onComplete callback
                _.opts.onComplete(_.$parent, _.isOpen);
            },
            ease: Expo.easeInOut
        });

        if(_.opts.scroll) {
            let scroll = _.isOpen ? _.$container.getBoundingClientRect().top + window.scrollY - document.querySelector('.js-header').offsetHeight: _.$parent.getBoundingClientRect().top + window.scrollY;

            TweenLite.to(window, 1, {
                scrollTo: {
                    y: scroll,
                    autoKill: false
                },
                onUpdate: _.opts.onUpdate,
                ease: Expo.easeInOut
            })
        }
    }
}

