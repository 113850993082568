import config from '../config';
import Default from './default'

import Slider from '../components/slider'

export default class LongStays extends Default {

    constructor() {
        super()
        const _ = this;

        // _.slider = new Slider('.js-slider', {
        //     flickity: {
        //         prevNextButtons: false,
        //         watchCSS: false
        //     }
        // })
    }
}
