import config from '../config';
import Lazy from '../components/lazy';
import Menu from '../components/menu';
import NaturalSticky from '../components/natural-sticky';
import BookingForm from '../components/booking-form'
import Reveal from '../components/reveal'
import CookiePolicy from '../components/cookiepolicy';
// import CookieLang from '../components/cookielang';

// import { smoothScroll } from '../utils'
import SmoothScroll from 'smooth-scroll'
import Cookie from 'js-cookie'

import countdown from 'countdown'
const discount = Symbol('discount')
const initPopup = Symbol('initPopup')
const resetTimer = Symbol('resetTimer')
const openTimeoutPopup = Symbol('openTimeoutPopup')



export default class Default {

    constructor() {
        const _ = this
        const $anchors = document.querySelectorAll('a[href*="#"]:not([href="#"])')

        _.timeoutPopupTimer = null
        _.timeoutPopupEnable = true
        _.timeoutTime = parseInt(document.body.dataset.timeoutTime, 10)

        if($anchors.length) {
            let scroll = new SmoothScroll();
            $anchors.forEach(function($anchor) {
                $anchor.addEventListener('click', function(e){
                    // smoothScroll( e.currentTarget.getAttribute('href') )
                    e.preventDefault()
                    let el = document.getElementById( e.currentTarget.getAttribute('href').replace('#', '') )
                    scroll.animateScroll(el, false, { offset: 75 })
                })
            })
        }

        _.lang = document.querySelector("html").getAttribute("lang")
        _.browserLanguage = navigator.language || navigator.userLanguage
        _.browserLanguage = _.browserLanguage.substr(0, 2)

        _.sliders = []

        // Reveal
        _.Reveal = new Reveal('.js-reveal')

        _.Lazy = new Lazy({
            onSuccess: function($img) {
                if($img.classList.contains('js-slider-img')) {
                    _.sliders.forEach(function(slider) {
                        slider.Flkty.resize()
                    })
                }
            }
        })

        // Booking Form
        // document.querySelectorAll('.js-bf').forEach(function(form){
        //     new BookingForm(form)
        // })
        let mainForm = document.querySelector('.js-bf[data-prefix="main-"]')
        if (mainForm) _.$mainForm = new BookingForm( mainForm )

        let alternateForm = document.querySelector('.js-bf[data-prefix="panel-"]')
        if (alternateForm) _.$alternateForm = new BookingForm( alternateForm )


        // Natural sticky detction
        new NaturalSticky

        // Cookie Policy
        // _.cookiePolicy = new CookiePolicy()

        // Lang cookie
        // _.cookieLang = new CookieLang()

        _.menu = new Menu({
            onPopupOpen: function(popup){
                if (popup.id == "popup-book") {
                    if (alternateForm) _.$alternateForm.parseCookie()
                }
            },
            onPopupClose: function(popup){
                if (popup.id == "popup-book") {
                    if (mainForm) _.$mainForm.parseCookie()
                }
            }
        })

        _[discount]()
        _[initPopup]()

        let timeoutPopupCookie = Cookie.get(config.cookies.timeoutPopup)

        if (timeoutPopupCookie === undefined && !isNaN(_.timeoutTime)) {
            _.resetHandle = _[resetTimer].bind(_)
            window.addEventListener('load', _.resetHandle)
            document.addEventListener('mousemove', _.resetHandle)
            document.addEventListener('keydown', _.resetHandle)
        }

    }
    

    [resetTimer](){
        const _ = this;
        if (_.timeoutPopupEnable) {
            clearTimeout(_.timeoutPopupTimer)
            _.timeoutPopupTimer = setTimeout(_[openTimeoutPopup].bind(_), _.timeoutTime * 1000)
        }
    }

    [openTimeoutPopup]() {
        const _ = this;
        _.timeoutPopupEnable = false
        clearTimeout(_.timeoutPopupTimer)
        document.querySelector('.js-popup-timeout').classList.add('is-open')
        Cookie.set(config.cookies.timeoutPopup, { done: true }, { expires: 7 })
    }

    [discount]() {
        const _ = this;

        _.$discount = document.querySelectorAll('.js-discount')

        _.$discount.forEach(function(el, index){
            let $discountDate = el.querySelector('.js-discount-date')

            let dataDays = el.querySelector('.js-discount-days')
            let dataHours = el.querySelector('.js-discount-hours')
            let dataMinutes = el.querySelector('.js-discount-minutes')
            let dataSeconds = el.querySelector('.js-discount-seconds')


            if ($discountDate) {
                let date = $discountDate.dataset.date
                if (date) {
                    countdown(
                        new Date(date),
                        function(ts) {
                            dataDays.innerHTML = ts.days
                            dataHours.innerHTML = ts.hours
                            dataMinutes.innerHTML = ts.minutes
                            dataSeconds.innerHTML = ts.seconds
                        },
                        countdown.DAYS|countdown.HOURS|countdown.MINUTES|countdown.SECONDS);
                }
            }
        })
    }

    [initPopup]() {
        const _ = this

        _.$popupOpen = document.querySelectorAll('[data-popup-open]')
        _.$popupClose = document.querySelectorAll('.js-popup-close')
        _.popupActive = 0

        _.$popupClose.forEach(($btn) => {
            let popup = $btn.closest('.js-popup')
            $btn.addEventListener('click', () => {

                popup.classList.remove('is-open')
                _.popupActive -= 1

                if (_.popupActive == 0) {
                    config.$body.classList.remove('popup-open')
                    config.$html.removeAttribute('style')
                }
            })
        })

        let timeoutPopupCloser = document.querySelector('.js-popup-timeout-close')
        if (timeoutPopupCloser) {
            document.querySelector('.js-popup-timeout-close').addEventListener('click', (e) => {
                e.currentTarget.closest('.js-popup').classList.remove('is-open')
                setTimeout(()=>{
                    document.querySelector('[data-popin-open="popin-book"]').click()
                }, 500)
            })
        }
    }
}
