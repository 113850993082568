import config from '../config';
import Default from './default'

import Slider from '../components/slider'

export default class Offers extends Default {

    constructor() {
        super()
        const _ = this;

        const $offerSliders = document.querySelectorAll('.js-offer-slider')
        $offerSliders.forEach(function($el) {
            _.sliders.push(new Slider($el, {
                flickity: {
                    pageDots: false,
                    autoPlay: true,
                    prevNextButtons: false,
                    wrapAround: true
                }
            }))
        })
    }
}
