
import config from '../config'
import $http from './ajaxget'

export default class TripAdvisor {

    // http://api.tripadvisor.com/api/partner/2.0/location/2522596?key=95298a5cbfad4c29bb72142d9ee6a123
    constructor(options) {
        const _ = this
        _.opts = {
            apiKey: '95298a5cbfad4c29bb72142d9ee6a123'
        };

        _.setOpts(options);
    }

    getOpts() {
        const _ = this;
        return _.opts;
    }

    setOpts(options) {
        const _ = this;
        _.opts = Object.assign({}, _.opts, options);
    }



    get(id) {
        const _ = this
        if (id) {
            const _ = this
            $http.ajaxGet(`//api.tripadvisor.com/api/partner/2.0/location/${id}?key=${_.opts.apiKey}`)
                .then((response) => {
                    let data = JSON.parse(response)
                    // _.total = 0
                    // _.ratings = false
                    if ( data.rating ) {
                        document.querySelector(".js-ta-rating").innerHTML = data.rating
                        document.querySelector(".js-ta-excellent").innerHTML = data.review_rating_count["5"]
                        document.querySelectorAll(".js-ta-url").forEach(function(el, index){
                            el.setAttribute('href', `${data.web_url}#REVIEWS`)
                        })
                    }
                })
                .catch(function(error) {
                })
        }
    }
}
