import config from '../config';
import Default from './default'
import Accordion from '../components/accordion'

export default class Faq extends Default {

    constructor() {
        super()
        const _ = this;

        const $accordions = document.querySelectorAll('.js-accordion')

        $accordions.forEach(function(el) {
            new Accordion(el)
        })
    }
}
