import config from '../config'

import Flatpickr from 'flatpickr'
import { fr } from 'flatpickr/dist/l10n/fr.js'
import { es } from 'flatpickr/dist/l10n/es.js'
import { de } from 'flatpickr/dist/l10n/de.js'
import { it } from 'flatpickr/dist/l10n/it.js'
import { nl } from 'flatpickr/dist/l10n/nl.js'
import { ru } from 'flatpickr/dist/l10n/ru.js'

import { TweenLite } from 'gsap'
import scrollTo from 'gsap/ScrollToPlugin';
import SlimSelect from 'slim-select'
import { inputLabel } from '../utils';

import Cookie from 'js-cookie'


export default class BookingForm {

    constructor($form) {
        const _ = this
        // console.log('BookingForm:constructor')

        _.cookieName = config.cookies.booking
        _.$form = $form
        _.position = _.$form.dataset.position
        _.isCookieParsed = false

        _.createCookie()
        _.datePicker()
        _.selector()
        _.guests()
        


        if(!_.$form.closest('.js-popin')) {
            _.$form.addEventListener('click', function() {
                
                let scrollToPos = _.position == 'up' ? 0 : _.$form.getBoundingClientRect().top + window.pageYOffset - document.querySelector('.l-header').offsetHeight
                TweenLite.to(window, 0.6, {
                    scrollTo : {
                        y: scrollToPos,
                        autoKill: true
                    },
                    ease: Expo.easeOut,
                    overwrite: 5
                });
            })
        }

        _.$form.querySelector('.c-bf__el--submit').addEventListener('click', function(e){
            // check hotel value for cities
            let selector = _.$form.querySelector('.js-bf-select')

            // BEGIN: "prom" fields hardcoded
            // let promoValue =  _.$form.querySelector('[name=prom]').value
            // if (promoValue == "") { 
            //     _.$form.querySelector('[name=prom]').value = 'BOOKERIC' 
            //     _.$form.querySelector('[name=prom]').focus()
            // }
            // END: "prom" fields hardcoded

            if(selector.value == '') {
                e.preventDefault()
                _.$form.querySelectorAll('.js-can-disabled').forEach(function(el){
                    el.classList.add('is-disabled')
                })
            }
            else {
                _.$form.querySelectorAll('.js-can-disabled').forEach(function(el){
                    el.classList.remove('is-disabled')
                })
            }
        });

        _.$form.querySelectorAll('.c-input-text__input').forEach(function(el){
            el.addEventListener('blur', function(){
                if (this.value != '') {
                    this.classList.add('is-filled')
                }
                else {
                    this.classList.remove('is-filled')
                }
            })
        })


        window.addEventListener('resize', _.hideAll.bind(_))

        document.addEventListener('click', function(e){
            let classname = e.target.getAttribute('class')
            
            if (classname && classname.indexOf('o-guest') < 0) {
                // _.hideAll()
                _.$form.querySelector('.js-bf-guests-popup').classList.remove('is-visible')
            }
        })

        _.parseCookie()

    }

    hideAll(event, options) {
        const _ = this
        if (event.detail === undefined) {
            _.$form.querySelector('.js-bf-select').slim.close()
            _.$form.querySelector('.js-bf-guests-popup').classList.remove('is-visible')
            _.$form.flatpickrIn.close()
        }
    }


    datePicker() {
        const _ = this
        // console.log('BookingForm:datePicker')

        _.$calendarTabs = _.$form.querySelector('.js-calendar-tabs')
        _.$calendarTabIn = _.$form.querySelector('.js-calendar-tab-in')
        _.$calendarTabOut = _.$form.querySelector('.js-calendar-tab-out')

        _.$dateIn = _.$form.querySelector('.js-bf-date-in')
        _.$dateOut = _.$form.querySelector('.js-bf-date-out')
        
        _.$datePickerIn = _.$dateIn.querySelector('.js-bf-date-picker')
        // _.$datePickerOut = _.$dateOut.querySelector('.js-bf-date-picker')

        _.$dateInHidden = _.$dateIn.querySelector('.js-bf-date-in-hidden')
        _.$dateOutHidden = _.$dateIn.querySelector('.js-bf-date-out-hidden')
        // _.$dateOutHidden = _.$dateOut.querySelector('.js-bf-date-out-hidden')
        
        let dateFormatOptions = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
        _.$form.flatpickrIn = Flatpickr(_.$datePickerIn, {
            // mode: 'range',
            minDate: 'today',
            dateFormat: 'j M',
            position: _.position == 'up' ? 'above' : 'below',
            appendTo: _.$datePickerIn.parentNode,
            animate: false,
            mode: "range",
            'locale': _.$datePickerIn.dataset.locale,
            onReady: function (selectedDates, dateStr, instance) {
                instance.element.nextElementSibling.placeholder = instance.element.nextElementSibling.nextElementSibling.innerHTML
                instance.calendarContainer.appendChild(_.$calendarTabs)
            },
            onChange: function (selectedDates, dateStr, instance) {
                let dateIn = selectedDates[0]
                let dateOut = selectedDates[1]
                
                if (instance.element.value != "") {
                    instance.element.nextElementSibling.placeholder = ""
                }

                if (dateIn){
                    _.updateCookie('dateIn', dateIn)
                    _.$dateInHidden.value = `${dateIn.getDate()}-${dateIn.getMonth() + 1}-${dateIn.getFullYear()}`
                    _.$calendarTabIn.innerHTML = dateIn.toLocaleDateString(_.$datePickerIn.dataset.locale, dateFormatOptions)
                    _.$calendarTabIn.classList.remove('is-active')
                    _.$calendarTabOut.classList.add('is-active')
                }
                if (dateOut){
                    _.updateCookie('dateOut', dateOut)
                    _.$dateOutHidden.value = `${dateOut.getDate()}-${dateOut.getMonth() + 1}-${dateOut.getFullYear()}`
                    _.$calendarTabOut.innerHTML = dateOut.toLocaleDateString(_.$datePickerIn.dataset.locale, dateFormatOptions)
                    setTimeout(function () {
                        _.$form.querySelector('.js-bf-guests-input').click()
                    }, 100)
                }

                _.$dateIn.querySelector('.c-input-text__input').classList.add('is-filled')

                // _.updateCookie('dateIn', selectedDates)
            },
            onClose: function(){
                _.$form.calendarInIsOpen = false
                clearInterval(_.$form.calendarTimeIn)
                // setTimeout(function () {
                //     _.$form.querySelector('.js-bf-guests-input').click()
                // }, 100)
            },
            onOpen: function(selectedDates, dateStr, instance){
                let dateIn = selectedDates[0]
                let dateOut = selectedDates[1]

                _.$form.querySelector('.js-bf-select').slim.close()
                _.$form.querySelector('.js-bf-guests-popup').classList.remove("is-visible")
                _.$calendarTabIn.classList.add('is-active')
                _.$calendarTabOut.classList.remove('is-active')

                if (dateIn) {
                    _.$calendarTabIn.innerHTML = dateIn.toLocaleDateString(_.$datePickerIn.dataset.locale, dateFormatOptions)
                }
                if (dateOut) {
                    _.$calendarTabOut.innerHTML = dateOut.toLocaleDateString(_.$datePickerIn.dataset.locale, dateFormatOptions)
                }

                
                clearInterval(_.$form.calendarTimeIn)
                _.$form.calendarTimeIn = setTimeout(function(){
                    _.$form.calendarInIsOpen = true
                }, 500)
            }
        })

        _.$form.flatpickrIn.l10n.rangeSeparator = ' - '

        _.$datePickerIn.addEventListener('click', function(e){
            e.stopPropagation()
            e.preventDefault()
            if (_.$form.calendarInIsOpen) {
                _.$form.calendarInIsOpen = false
                clearInterval(_.$form.calendarTimeIn)
                _.$form.flatpickrIn.close()
            }
        })

        // _.$datePickerOut.addEventListener('click', function(e){
        //     e.stopPropagation()
        //     e.preventDefault()
        //     if (_.$form.calendarOutIsOpen) {
        //         _.$form.calendarOutIsOpen = false
        //         clearInterval(_.$form.calendarTimeOut)
        //         _.$form.flatpickrOut.close()
        //     }
        // })

    }

    setCalendarTabDates(selectedDates){

    }

    selector() {
        const _ = this
        // console.log('BookingForm:selector')
        const $hotelSelect = _.$form.querySelector('.js-bf-select')

        _.select = new SlimSelect({
            select: $hotelSelect,
            placeholder: $hotelSelect.dataset.placeholder,
            showSearch: false,
            showContent: _.position,
            onChange: function(info, a, b){
                let currencyField = _.$form.querySelector('.js-bf-currency')

                let language = document.getElementsByTagName('html')[0].getAttribute('lang')
                let action = `https://reservations.ericvokel.com/${!info.data.hotel ? info.value : info.value.split('#')[1] }/${language}/availability/`
                _.$form.setAttribute('action', action)

                if (info.data.hotel) {
                    _.$form.querySelector('.js-bf-hotel').value = info.data.hotel
                }
                else {
                    _.$form.querySelector('.js-bf-hotel').value = ''
                }

                _.updateCapacity(_.$form)
                _.$form.querySelectorAll('.c-bf__el.is-disabled').forEach(function(el){
                    el.classList.remove('is-disabled')
                })

                if ( _.isCookieParsed && Cookie.get( _.cookieName ) ) {
                    let cookie = JSON.parse( Cookie.get( _.cookieName ) )
                    if (cookie.hotel != info.value) { 
                        _.$form.flatpickrIn.open()
                    }
                }

                currencyField.value = 'EUR'

                if (info.value == "evokel_copenhagensuites") {
                    if (language == "en" || language == "da") {
                        currencyField.value = 'DKK'
                    }
                    else if(language == "ru") {
                        currencyField.value = 'RUB'
                    }
                }
                
                _.updateCookie('hotel', info.value)

            }
        })

        // _.$form.querySelectorAll('.ss-option').forEach(function(el, index){
        //     el.addEventListener("click", function(){
        //         console.log('lala')
        //         _.$form.flatpickrIn.open()
        //     })

        // })

    }

    guests() {
        const _ = this
        // console.log('BookingForm:guests')
        var $guests = _.$form.querySelector('.js-bf-guests')
        var $guestsPopup = $guests.querySelector('.js-bf-guests-popup')
        var $guestsInput = $guests.querySelector('.js-bf-guests-input')
        var $guest = $guests.querySelectorAll('.js-bf-guest')

        // Show / Hide guest popup
        // $guestsInput.addEventListener('focusin', function() {
        //     $guestsPopup.classList.add('is-visible')
        // })

        $guestsInput.addEventListener('click', function() {
            $guestsPopup.classList.toggle('is-visible')
            _.$form.querySelector('.js-bf-select').slim.close()
        })

        _.$form.querySelectorAll('.c-bf__el:not(.js-bf-guests)').forEach(function(el){
            el.addEventListener('focusin', function(){
                $guestsPopup.classList.remove('is-visible')
            })
        })

        _.$form.querySelector('.js-bf-guests-input').addEventListener('click', function(e){
            e.stopPropagation()
        })

        // Disable user select on input
        $guestsInput.addEventListener('mousedown', function(e) {
            e.preventDefault()
        });

        // $guestsInput.addEventListener('focusout', function() {
        //     $guestsPopup.classList.remove('is-visible')
        //     console.log('focusout')
        // })


        // Manage guest input values
        $guest.forEach(function(guest) {
            var $add = guest.querySelector('.js-bf-guest-add')
            var $remove = guest.querySelector('.js-bf-guest-remove')
            var $input = guest.querySelector('.js-bf-guest-input')

            var min = $input.min
            var max = $input.max

            $add.addEventListener('click', function() {
                let max = parseInt($input.max, 10)
                let newValue = parseInt($input.value, 10) + 1
                $input.value = newValue <= max ?  newValue : $input.value
                _.updatePassengers(_.$form, $input)
            })

            $remove.addEventListener('click', function() {
                let min = parseInt($input.min, 10)
                let newValue = parseInt($input.value, 10) - 1

                $input.value = newValue >= min ?  newValue : $input.value
                _.updatePassengers(_.$form, $input)
            })
        })
    }

    updateCapacity(form){
        const _ = this

        let $hotelSelect = form.querySelector('.js-bf-select')
        let prefix = form.dataset.prefix
        let maxCapacity = $hotelSelect.options[$hotelSelect.selectedIndex].dataset.capacity


        let adults = document.getElementById(`${prefix}js-bf-guest-adult`)
        let childrens = document.getElementById(`${prefix}js-bf-guest-children`)

        adults.setAttribute('max', maxCapacity)
        childrens.setAttribute('max', 2)

        if (parseInt(adults.value, 10) + parseInt(childrens.value, 10) > maxCapacity ) {
            adults.value = maxCapacity
            // childrens.value = 0
        }

        _.updatePassengers(form, adults)
        _.updatePassengers(form, childrens)
    }

    updatePassengers(form, $input){
        const _ = this

        let prefix = form.dataset.prefix
        let adults = document.getElementById(`${prefix}js-bf-guest-adult`)
        let adultsValue = parseInt(adults.value, 10)
        let childrens = document.getElementById(`${prefix}js-bf-guest-children`)
        let childrensValue = parseInt(childrens.value, 10)
        let maxCapacity = parseInt($input.max, 10)

        form.querySelector('.js-bf-guests-input').classList.add('is-filled')

        _.updateCookie('adults', adults.value)
        _.updateCookie('childs', childrens.value)

        form.querySelector( '.js-bf-guests-input').value = `${adults.value}` + ( childrens.value > 0 ? ` + ${childrens.value}` : '' )
    }

    createCookie(){
        const _ = this
        if (!Cookie.get(_.cookieName)) {
            Cookie.set(_.cookieName, { hotel: false, dates: false, dateIn: false, dateOut: false, adult: 1, childs: 0 }, { expires: 365 })
        }
    }

    updateCookie(key, value) {
        const _ = this
        try {
            let cookie = JSON.parse( Cookie.get( _.cookieName ) )
            cookie[key] = value
            Cookie.set( _.cookieName, cookie, { expires: 365 } )
        } catch(e) {}
    }

    parseCookie(_form){
        const _ = this
        let $form = _form ? _form : _.$form

        if ( Cookie.get( _.cookieName ) ) {
            let cookie = JSON.parse( Cookie.get( _.cookieName ) )

            let prefix = $form.dataset.prefix
            let hotel = $form.dataset.hotel != "" ? $form.dataset.hotel : false

            // Parse hotel
            if (hotel) {
                $form.querySelector('.js-bf-select').slim.set( hotel )
            }
            else if ( cookie.hotel ) {
                $form.querySelector('.js-bf-select').slim.set( cookie.hotel )
            }
            // Parse dates
            
            // if ( cookie.dates ) {
            //     $form.querySelector('.js-bf-date-picker').classList.add('is-filled')
            //     $form.flatpickrIn.setDate(cookie.dateIn)
            //     $form.flatpickrOut.setDate(cookie.dateOut)

            //     if (cookie.dates[0]) {
            //         let date = new Date(cookie.dates[0])
            //         $form.querySelector('.js-bf-date-in').value = _.$dateInHidden.value = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`
            //     }
            //     if (cookie.dates[1]) {
            //         let date = new Date(cookie.dates[1])
            //         $form.querySelector('.js-bf-date-out').value = _.$dateOutHidden.value = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`
            //     }
            // }
            if (cookie.dateIn) {
                let date = new Date(cookie.dateIn)
                _.$dateInHidden.value = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`

                if (cookie.dateOut) {
                    let date = new Date(cookie.dateOut)
                    _.$dateOutHidden.value = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`
                    $form.flatpickrIn.setDate([cookie.dateIn, cookie.dateOut])
                }
                else {
                    $form.flatpickrIn.setDate(cookie.dateIn)
                }

                _.$dateInHidden.previousElementSibling.innerHTML = ''
            }

            // Parse passengers
            if ( cookie.adults ) {
                let adults = document.getElementById(`${prefix}js-bf-guest-adult`)
                adults.value = cookie.adults

            }
            // Parse childs
            if ( cookie.childs ) {
                let childs = document.getElementById(`${prefix}js-bf-guest-children`)
                childs.value = cookie.childs
            }

            if ( cookie.adults || cookie.childs ) {
                let el = $form.querySelector('.js-bf-guests-input')
                el.value = `${cookie.adults}` + ( cookie.childs > 0 ? ` + ${cookie.childs}` : '' )
                el.classList.add('is-filled')
                $form.querySelector( '.js-bf-guests-input').value = `${cookie.adults}` + ( cookie.childs > 0 ? ` + ${cookie.childs}` : '' )
                $form.querySelector( '.js-bf-guests-input').classList.add('is-filled')
            }
        }

        _.isCookieParsed = true
    }
}
