import config from '../config';
import Default from './default'

import Map from '../components/map'
import Slider from '../components/slider'
import Accordion from '../components/accordion'
import TripAdvisor from '../components/trip-advisor'

import Cookie from 'js-cookie'

export default class Hotel extends Default {

    constructor() {
        super()
        const _ = this;

        /*
            Init map
        */
        _.cookieName = config.cookies.booking
        _.map = new Map


        /*
            Setup Sliders
        */

        // Hero
        const $heroSliders = document.querySelector('.js-slider-hero')
        if ($heroSliders) {
            _.sliders.push(new Slider($heroSliders, {
                flickity: {
                    pageDots: false,
                    autoPlay: 5000,
                    wrapAround: true
                }
            }))
        }

        // Appartments
        const sliders = []
        const $sliders = document.querySelectorAll('.js-slider')
        $sliders.forEach(function($slider) {
            _.sliders.push(new Slider($slider, {
                flickity: {
                    wrapAround: true
                }
            }))
        })

        // Sub navigation
        if(!config.isIe) _.subnav()


        // Accordions
        const $accordions = document.querySelectorAll('.js-accordion')
        $accordions.forEach(function(el) {
            new Accordion(el, {
                scroll: true
            })
        })

        document.querySelectorAll('.js-check-availability').forEach(function(el, index){
            el.addEventListener("mousedown", function(e){
                let form = document.querySelector('.js-bf[data-prefix="main-"]')
                let urlBase = e.currentTarget.dataset.url
                let city = e.currentTarget.dataset.city
                let lang = e.currentTarget.dataset.lang
                let adults = form.querySelector("[name=adults]").value
                let childrens = form.querySelector("[name=children]").value
                let cookieConsent = form.querySelector("[name=cookieConsent]").value
                let promo = form.querySelector("[name=promo]") ? form.querySelector("[name=promo]").value : ''
                
                // let dates = form.flatpickr().selectedDates
                let dateIn = ''
                // if (dates && dates.length) dateIn = `${dates[0].getDate()}-${(dates[0].getMonth() + 1)}-${dates[0].getFullYear()}`
                let dateOut = ''
                // if (dates && dates.length > 1) dateOut = `${dates[1].getDate()}-${(dates[1].getMonth() + 1)}-${dates[1].getFullYear()}`

                if (Cookie.get(_.cookieName)) {
                    let cookie = JSON.parse(Cookie.set(_.cookieName))
                    if (cookie.dateIn) {
                        let date = new Date(cookie.dateIn)
                        dateIn = `${date.getDate()}-${(date.getMonth() + 1)}-${date.getFullYear()}`
                    }
                    if (cookie.dateOut) {
                        let date = new Date(cookie.dateOut)
                        dateOut = `${date.getDate()}-${(date.getMonth() + 1)}-${date.getFullYear()}`
                    }
                }

                e.currentTarget.href = `${urlBase}/${city}/${lang}/availability/?adults=${adults}&children=${childrens}&city=${city}&cookieConsent=${cookieConsent}&datein=${dateIn}&dateout=${dateOut}&hotel=promo=${promo}`
            })
        });

        _.ta = new TripAdvisor()
        _.ta.get(document.querySelector('[data-tripadvisorid]').dataset.tripadvisorid || false)

    }

    subnav() {
        const _ = this;

        _.unbindUpdate = false

        // Nav item click
        _.$subnav = document.querySelector('.js-subnav')
        _.$subnavLinks = _.$subnav.querySelectorAll('.js-subnav-link')

        _.appartmentsArray = []
        _.$subnavAppartments = document.querySelectorAll('.js-subnav-appartment')
        _.$subnavAppartments.forEach(function($el, i) {
            _.appartmentsArray[i] = {
                el: $el,
                id: `#${$el.getAttribute('id')}`,
                active: false
            }
        })


        // Update scroll
        const update = function() {

            if(_.unbindUpdate) return

            _.scrollY = window.scrollY + 200
            _.appartmentsArray.forEach(function($el, i) {
                if(_.scrollY >= $el.offsetTop && _.scrollY < $el.offsetTop + $el.height) {
                    if(!$el.active) {
                        $el.active = true;
                        _.$currentItem = _.$subnav.querySelector(`.js-subnav-link[href="${_.appartmentsArray[i].id}"]`)

                        _.$currentItem.classList.add('is-selected')
                    }

                } else if($el.active) {
                    $el.active = false;
                    document.querySelector(`.js-subnav-link[href="${_.appartmentsArray[i].id}"]`).classList.remove('is-selected')
                }
            })
        }

        // Set height / offset
        const resize = function() {
            _.$subnavAppartments.forEach(function($el, i) {
                _.appartmentsArray[i].offsetTop = Math.round($el.getBoundingClientRect().top + pageYOffset)
                _.appartmentsArray[i].height = $el.offsetHeight
            })

            update()
        }


        _.$subnavLinks.forEach(function($link) {
            $link.addEventListener('click', function(e) {

                const $target = _.appartmentsArray[_.appartmentsArray.findIndex(section => section.id == $link.hash)].el

                TweenLite.to(window, 0.6, {
                    scrollTo: {
                        y: config.W.w > 768 ? $target.getBoundingClientRect().top + window.pageYOffset - _.$subnav.offsetHeight : $target.getBoundingClientRect().top + window.pageYOffset,
                        autoKill: false
                    },
                    onStart() {
                        _.unbindUpdate = true
                    },
                    onComplete() {
                        _.unbindUpdate = false
                        update()
                    },
                    ease: Expo.easeInOut
                })
            })
        })

        resize()

        window.addEventListener('scroll', update)
        window.addEventListener('resize', resize)

        _.Lazy.opts.onSuccess = function($img) {
            if($img.classList.contains('js-slider-img')) {
                if(config.browser.isIe || config.browser.isEdge) {
                    var event = document.createEvent('HTMLEvents');
                    event.initEvent('resize', true, false);
                    window.dispatchEvent(event);
                } else {
                    _.sliders.forEach(function(slider) {
                        slider.Flkty.resize()
                    })
                    resize()
                }
            }
        }
    }
}
