import Flickity from 'flickity'
// import Magnetic from './magnetic'


export default class Slider {

    constructor(selector, options={}){
        const _ = this;
        // console.log('Slider:constructor');

        _.opts = {
            counter: false,
            clickSelect: false,
            clickNext: false,
            expand: false,
            flickity: {
                arrowShape: 'M100,52.6H9.5l11.6,12.6l-3.8,3.5L0,50l17.3-18.7l3.8,3.5L9.5,47.4H100V52.6z',
                selectedAttraction: 0.08,
                friction: 0.5,
                prevNextButtons: true
            }
        };

        _.$selector = (typeof selector == 'string' || selector instanceof String) ? document.querySelector(selector) : selector

        _.setOpts(options);

        _.Flkty = new Flickity(_.$selector, _.opts.flickity)
        
        _.Flkty.on("change", function(a){
            // let resizeEvent = window.document.createEvent('UIEvents'); 
            // resizeEvent.initUIEvent('resize', true, false, window, 0); 

            let resizeEvent = new CustomEvent("resize", { detail: { origin: 'slider' } });
            window.dispatchEvent(resizeEvent)
        })


        if(_.opts.counter) _.counter()
        if(_.opts.clickSelect) _.clickSelect()
        if(_.opts.clickNext) _.clickNext()
        if(_.opts.expand) _.expand()

        if(_.opts.flickity.prevNextButtons) {
            // new Magnetic(_.$selector.querySelector('.flickity-prev-next-button.next'))
        }
    }

    getOpts() {
        const _ = this;
        // console.log('Slider:getOpts');
        return _.opts;
    }

    setOpts(options) {
        const _ = this;
        // console.log('Slider:setOpts');
        let flickityOpts = Object.assign({}, _.opts.flickity, options.flickity);
        _.opts = Object.assign({}, _.opts, options);
        _.opts.flickity = flickityOpts
    }

    counter() {
        const _ = this;
        // console.log('Slider:counter');
        _.$counter = _.$selector.parentNode.querySelector('.js-slider-counter')
        _.$counter.innerHTML = _.Flkty.selectedIndex + 1


        _.Flkty.on('select', function() {
            _.$counter.innerHTML = _.Flkty.selectedIndex + 1
        })
    }

    clickSelect() {
        const _ = this;
        // console.log('Slider:clickSelect');
        _.Flkty.on('staticClick', function(event, pointer, cellElement, cellIndex) {
            if (typeof cellIndex == 'number') {
                _.Flkty.selectCell(cellIndex);
            }
        });
    }

    clickNext() {
        const _ = this;
        // console.log('Slider:clickNext');
        _.Flkty.on('staticClick', function() {
            _.Flkty.next();
        });
    }

    expand() {
        const _ = this;
        // console.log('Slider:expand');

        // Vars
        _.$slider = _.Flkty.slider
        _.$expandBtn = _.$selector.parentNode.querySelector('.js-slider-expand')

        _.expandSlider = _.opts.expand
        _.$expandSlider = _.expandSlider.$selector.parentNode
        _.$closeSlider = _.$expandSlider.querySelector('.js-slider-close')

        // Expand btn
        _.$slider.addEventListener('mousemove', function(e) {
            _.$expandBtn.style.top = `${e.offsetY}px`
            _.$expandBtn.style.left = `${e.offsetX}px`
        })

        _.$slider.addEventListener('mouseenter', function(e) {
            _.$expandBtn.classList.add('is-visible')
        })

        _.$slider.addEventListener('mouseleave', function(e) {
            _.$expandBtn.classList.remove('is-visible')
        })

        // Open fullscreen slider
        _.$slider.addEventListener('click', function(e) {
            _.$expandSlider.classList.add('is-open')
        })

        // Close fullscreen slider
        _.$closeSlider.addEventListener('click', function(e) {
            _.$expandSlider.classList.remove('is-open')
            _.Flkty.select(_.expandSlider.Flkty.selectedIndex, false, true)
        })

        document.addEventListener('keydown', function(e) {
             if (e.keyCode == 27 && _.$expandSlider.classList.contains('is-open')) {
                _.$expandSlider.classList.remove('is-open')
                _.Flkty.select(_.expandSlider.Flkty.selectedIndex, false, true)
            }
        });

        // Update both slide son select
        _.Flkty.on('select', (i) => {
            _.expandSlider.Flkty.select(i)
        })
    }
}
