const config = {

    $html : document.documentElement,
    $body : document.getElementsByTagName('body')[0],
    $header : document.querySelector('.l-header'),
    $footer : document.querySelector('.l-footer'),

    W : {
        w : window.innerWidth,
        h : window.innerHeight
    },

    isRetina: window.devicePixelRatio >= 1.5,

    browser : {
        // Opera 8.0+
        isOpera : (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0,

        // Firefox 1.0+
        isFirefox : typeof InstallTrigger !== 'undefined',

        // Safari 3.0+ "[object HTMLElementConstructor]"        p.toString() === "[object SafariRemoteNotification]"; })(!window.safari || safari.pushNotification),
        isSafari : Object.prototype.toString.call(window.HTMLElement).indexOf('Constructor') > 0 || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window.safari || safari.pushNotification),

        // Internet Explorer 6-11
        isIE : /*@cc_on!@*/false || !!document.documentMode,

        // Edge 20+
        isEdge : !(/*@cc_on!@*/false && !!document.documentMode) && !!window.StyleMedia,

        // Chrome 1+
        isChrome : !!window.chrome && !!window.chrome.webstore
    },

    cookies: {
        booking : 'eric-book',
        cookiesPolicy : 'ckConsent',
        timeoutPopup : 'eric-timeout'
    },

    browserName :
        (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0 ? 'opera' :
        typeof InstallTrigger !== 'undefined' ? 'firefox' :
        Object.prototype.toString.call(window.HTMLElement).indexOf('Constructor') > 0 || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window.safari || safari.pushNotification) ? 'safari' :
        /*@cc_on!@*/false || !!document.documentMode ? 'ie' :
        !(/*@cc_on!@*/false && !!document.documentMode) && !!window.StyleMedia ? 'edge' :
        !!window.chrome && !!window.chrome.webstore ? 'chrome' :
        false,

    deviceOS :
        /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream ? 'ios' :
        /windows phone/i.test(navigator.userAgent) ? 'window' :
        /android/i.test(navigator.userAgent) ? 'android' :
        false



};

export default config;
