// import GoogleMapsLoader from 'google-maps'
import { Loader } from 'google-maps';

export default class Map {

    constructor(map='.js-map', options={}) {
        const _ = this;

        _.opts = {
            hotels: '.js-map-item',
            zoomPopup: -1,
            map: {
                streetViewControl: false,
                disableDefaultUI: true,
                styles: [
                    {
                        "featureType":"all",
                        "elementType":"all",
                        "stylers":[{"saturation":"-50"}]
                    },
                    {
                        "featureType":"all",
                        "elementType":"geometry.fill",
                        "stylers":[{"saturation":"-2"},{"visibility":"on"},{"color":"#ffffff"}]
                    },
                    {
                        "featureType":"administrative",
                        "elementType":"labels.text.fill",
                        "stylers":[{"color":"#676767"}]
                    },
                    {
                        "featureType":"landscape.man_made",
                        "elementType":"geometry",
                        "stylers":[
                            {"color":"#e7e7e7"}
                        ]
                    },
                    {
                        "featureType":"landscape.natural",
                        "elementType":"geometry",
                        "stylers":[{"color":"#d9ecbe"},{"saturation":"-17"}]
                    },
                    {
                        "featureType":"poi",
                        "elementType":"all",
                        "stylers":[{"visibility":"on"}]
                    },
                    {
                        "featureType":"poi",
                        "elementType":"labels",
                        "stylers":[{"visibility":"off"}]
                    },
                    {
                        "featureType":"poi",
                        "elementType":"labels.text.fill",
                        "stylers":[{"visibility":"on"}, {"color":"#8c8c8c"}]
                    },
                    {
                        "featureType":"poi",
                        "elementType":"labels.text.stroke",
                        "stylers":[{"visibility":"off"}]
                    },
                    {
                        "featureType":"poi",
                        "elementType":"labels.icon",
                        "stylers":[{"visibility":"on"},{"hue":"#00ffe0"}]
                    },
                    {
                        "featureType":"poi.business",
                        "elementType":"all",
                        "stylers":[{"visibility":"off"}]
                    },
                    {
                        "featureType":"poi.medical",
                        "elementType":"geometry",
                        "stylers":[{"color":"#fbd3da"}]
                    },
                    {
                        "featureType":"poi.park",
                        "elementType":"geometry",
                        "stylers":[{"color":"#cee6b5"}]
                    },
                    {
                        "featureType":"road",
                        "elementType":"all",
                        "stylers":[{"visibility":"on"}]
                    },
                    {
                        "featureType":"road",
                        "elementType":"geometry.stroke",
                        "stylers":[{"visibility":"off"}]
                    },
                    {
                        "featureType":"road",
                        "elementType":"labels",
                        "stylers":[{"visibility":"off"}]
                    },
                    {
                        "featureType":"road",
                        "elementType":"labels.text",
                        "stylers":[{"visibility":"on"}]
                    },
                    {
                        "featureType":"road.highway",
                        "elementType":"geometry.fill",
                        "stylers":[{"color":"#efe0a0"}]
                    },
                    {
                        "featureType":"road.highway",
                        "elementType":"geometry.stroke",
                        "stylers":[{"color":"#efe1a9"}]
                    },
                    {
                        "featureType":"road.arterial",
                        "elementType":"geometry.fill",
                        "stylers":[{"color":"#ffffff"}]
                    },
                    {
                        "featureType":"road.local",
                        "elementType":"geometry.fill",
                        "stylers":[{"color":"black"}]
                    },
                    {
                        "featureType":"transit.station.airport",
                        "elementType":"geometry.fill",
                        "stylers":[{"color":"#cfb2db"}]
                    },
                    {
                        "featureType":"water",
                        "elementType":"geometry",
                        "stylers":[{"color":"#bcdfee"}]
                    }]
            }
        }

        _.setOpts(options);

        const loader = new Loader('AIzaSyCVe-fD8DkiYYAWpRxkolSVpv89Fcoj5zE', {});

        loader.load().then(function (google) {
            _.map = new google.maps.Map(document.querySelector(map), _.opts.map);

            _.definePopup()
            _.initPopup(_.opts.hotels)
        });

        // GoogleMapsLoader.KEY = 'AIzaSyCVe-fD8DkiYYAWpRxkolSVpv89Fcoj5zE'
        // GoogleMapsLoader.VERSION = 3.5
        // GoogleMapsLoader.load((google) => {

        //     _.map = new google.maps.Map(document.querySelector(map), _.opts.map);

        //     _.definePopup()
        //     _.initPopup(_.opts.hotels)
        // })
    }

    setOpts(options) {
        const _ = this;
        // console.log('Map:setOpts', options);
        options.map = Object.assign({}, _.opts.map, options.map);
        _.opts = Object.assign({}, _.opts, options);
    }

    initPopup(items) {
        const _ = this

        const $items = document.querySelectorAll(items)
        let lat, lng
        _.popups = []
        $items.forEach(function($hotel, i) {
            lat = parseFloat($hotel.dataset.lat)
            lng = parseFloat($hotel.dataset.lng)

            _.popups.push(new _.Popup(
                new google.maps.LatLng(lat, lng),
                $hotel
            ));
            _.popups[i].setMap(_.map);
        })

        google.maps.event.addListenerOnce(_.map, 'bounds_changed', function(event) {
            this.setZoom(_.map.getZoom() + _.opts.zoomPopup);

            if (this.getZoom() > 14) {
                this.setZoom(14);
            }
        });

        // Center map within the markers
        const bounds = new google.maps.LatLngBounds();
        _.popups.forEach(function(popup) {
            bounds.extend(popup.position)
        })
        _.map.fitBounds(bounds)

        window.addEventListener('resize', function() {
            _.map.fitBounds(bounds)
            _.map.setZoom(_.map.getZoom() + _.opts.zoomPopup);

            if (_.map.getZoom() > 14) {
                _.map.setZoom(14);
            }
        })
    }

    definePopup() {
        const _ = this

        /**
        * A customized popup on the map.
        * @param {!google.maps.LatLng} position
        * @param {!Element} content
        * @constructor
        * @extends {google.maps.OverlayView}
        */

        _.Popup = function(position, anchor) {
            this.position = position;
            this.anchor = anchor;
            // Optionally stop clicks, etc., from bubbling up to the map.
            this.stopEventPropagation();
        };

        // NOTE: google.maps.OverlayView is only defined once the Maps API has
        // loaded. That is why Popup is defined inside initMap().
        _.Popup.prototype = Object.create(google.maps.OverlayView.prototype);

        /** Called when the popup is added to the map. */
        _.Popup.prototype.onAdd = function() {
            this.getPanes().floatPane.appendChild(this.anchor);
            this.anchor.classList.add('is-visible')
        };

        /** Called when the popup is removed from the map. */
        _.Popup.prototype.onRemove = function() {
            if (this.anchor.parentElement) {
                this.anchor.parentElement.removeChild(this.anchor);
            }
        };

        /** Called when the popup needs to draw itself. */
        _.Popup.prototype.draw = function() {
            var divPosition = this.getProjection().fromLatLngToDivPixel(this.position);
            // Hide the popup when it is far out of view.
            var display =
                Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000 ?
                'block' :
                'none';

            if (display === 'block') {
                this.anchor.style.left = divPosition.x + 'px';
                this.anchor.style.top = divPosition.y + 'px';
            }
            if (this.anchor.style.display !== display) {
                this.anchor.style.display = display;
            }
        };

        /** Stops clicks/drags from bubbling up to the map. */
        _.Popup.prototype.stopEventPropagation = function() {
            var anchor = this.anchor;
            anchor.style.cursor = 'auto';

            ['click', 'dblclick', 'contextmenu', 'wheel', 'mousedown', 'touchstart', 'pointerdown']
            .forEach(function(event) {
                anchor.addEventListener(event, function(e) {
                    e.stopPropagation();
                });
            });
        }
    }
}
