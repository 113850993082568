import config from '../config';
import Default from './default'
import Map from '../components/map'

export default class City extends Default {

    constructor() {
        super()
        const _ = this;

        _.map = new Map()
    }
}
