'use strict';
import $ from 'jquery';
import config from '../config'
import { TweenLite } from 'gsap'


export default class Parallax {

    constructor(options){
        const _ = this;
        // console.log('Parallax:constructor');

        _.opts = {
            container: '.js-parallax',
            offset: 190,
            duration: 0.3
        };

        _.setOpts(options);

        _.init()


        window.addEventListener('scroll', _.update.bind(_))
    }

    getOpts() {
        const _ = this;
        // console.log('Parallax:getOpts', _.opts);
        return _.opts;
    }

    setOpts(options) {
        const _ = this;
        // console.log('Parallax:setOpts');
        $.extend(true, _.opts, options);
        _.$container = $(_.opts.container)
    }

    init() {
        const _ = this;
        // console.log('Parallax:init');

        _.elements = []

        _.$container.each(function(i, item) {
            _.elements.push([])
            $(item).find('[data-dept-x], [data-dept-y]').each(function(j, el) {
                let $el = $(el)
                let dept = {
                    x: $el.attr('data-dept-x') ? $el.attr('data-dept-x') : 0,
                    y: $el.attr('data-dept-y') ? $el.attr('data-dept-y') : 0,
                }
                _.elements[i].push({
                   el: el,
                   dept: dept
                })
                $el.removeAttr('data-dept-x, data-dept-y')
            });
        });
    }

    update() {
        const _ = this;
        // console.log('Parallax:setOpts')
        let distance, elOffset, ratio;

        _.$container.each(function(i, el) {
            let $el = $(el);

            elOffset = ($el.offset().top  - window.scrollY) - _.opts.offset;
            distance = window.scrollY - $el.offset().top;
            ratio = distance/4 - elOffset;

            _.elements[i].forEach(function(item) {
                TweenLite.to(item.el, _.opts.duration, {
                    x: item.dept.x ? ratio * item.dept.x : 0,
                    y: item.dept.y ? ratio * item.dept.y : 0
                });
            })
        });
    }
}
