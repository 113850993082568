import { TweenLite } from 'gsap'
import scrollTo from 'gsap/ScrollToPlugin';

/**
 * Animate scroll for anchor links
 * @return {void}
 */
export const smoothScroll = function(e) {
    e.preventDefault()
    debugger
    if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
        const $target = document.querySelector(this.hash)
        if ($target) {
            let offset = (document.querySelector('.l-header').offsetHeight) + 20
            TweenLite.to(window, 0.6, {
                scrollTo: {
                    y: $target.getBoundingClientRect().top + window.pageYOffset - offset,
                    autoKill: false
                },
                ease: Expo.easeInOut
            })
            return false
        }
    }
}


/*
 * Smooth Scroll plugin
 * @return {void}
 */
export const smoothMouse = function() {

    const $html = document.documentElement
    const scrollTime = 0.6;
    const scrollDistance = 170;
    let delta, finalScroll

    var smooth = function(e){
        e.preventDefault();
        delta = e.wheelDelta/120 || -e.detail/3;
        finalScroll = window.scrollY - parseInt(delta * scrollDistance);

        TweenLite.to($html, scrollTime, {
            scrollTo : {
                y: finalScroll,
                autoKill: true
            },
            ease: Expo.easeOut,
            overwrite: 5
        });
    };

    window.addEventListener('mousewheel', smooth, false);
    window.addEventListener('DOMMouseScroll', smooth, false);
}


/*
 * Input label plugin
 * @return {void}
 */
export const inputLabel = function() {
    let input = this
    console.log(input.value)

    if(input.value !== '') {
        input.classList.add('is-filled')
    } else {
        input.classList.remove('is-filled')
    }
}
