'use strict';

import config from '../config'
const onPolicyClose = Symbol('onPolicyClose')

export default class CookiePolicy {

    constructor(opts) {
        const _ = this

        // Default options
        _.opts = {
            cookieName: config.cookies.cookiesPolicy,
            selector: '.js-cookies',
            closeSelector: '.js-cookies-close',
            isVisibleClass: 'is-visible'
        }

        _.el = document.querySelector( _.opts.selector ) || false
        _.close = document.querySelector( _.opts.closeSelector ) || false

        // Extend options
        _.setOpts(opts)

        // If cookie don't exist, show policy wrapper
        if ( !_.isAccepted() ) {
            if (_.el) { _.el.classList.add( _.opts.isVisibleClass ); }
            if (_.close) { _.close.addEventListener("click", _[onPolicyClose].bind(_)); }
        }

        document.querySelectorAll(".js-bf-cookie").forEach(function(el, index){
            el.value = _.isAccepted() ? 1 : 0
        })
    }

    setOpts(opts) {
        Object.assign(this.opts, opts)
    }

    getCookieValue() {
        const _ = this
        var match = document.cookie.match(new RegExp('(^| )' + _.opts.cookieName + '=([^;]+)'));
        if (match) return match[2];
        return false
    }

    isAccepted() {
        const _ = this
        return document.cookie.indexOf( _.opts.cookieName + "=" ) > -1 && _.getCookieValue() == 'true'
    }

    [onPolicyClose]() {
        const _ = this
        let cookieStr = '';
        let expirationDate = new Date();

        // Hide Cookies Policy wrapper
        _.el.classList.remove( _.opts.isVisibleClass )

        // Set cookie
        expirationDate.setFullYear(expirationDate.getFullYear() + 1);
        cookieStr = `${_.opts.cookieName}=true; path=/; expires=${expirationDate.toUTCString()}`
        document.cookie = cookieStr;


        document.querySelectorAll(".js-bf-cookie").forEach(function(el, index){ el.value = 1 })

    }
}
