export default class NaturalSticky {

    constructor() {
        const _ = this
        _.stickies = Array.from(document.querySelectorAll('.js-sticky'));
        window.addEventListener('scroll', _.scroll.bind(_))

    }

    scroll(e) {
        this.stickies.filter(header => {
            const headerTop = header.getBoundingClientRect().top;
            const originalTop = parseInt(getComputedStyle(header).top, 10)

            if (headerTop == originalTop) {
                header.classList.add("is-sticky")
            }
            else {
                header.classList.remove("is-sticky")   
            }
        });
    }

}
