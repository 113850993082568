import { TweenLite } from 'gsap'
import config from '../config'

export default class Menu {

    constructor(options) {
        const _ = this

        _.opts = {
            onPopupOpen: function(){},
            onPopupClose: function(){}
        }

        _.setOpts(options);

        _.$header = document.querySelector('.js-header')
        window.addEventListener('scroll', _.scroll.bind(_))

        _.initPopin()
    }

    setOpts(options) {
        const _ = this
        Object.assign(_.opts, options)
    }

    scroll() {
        const _ = this

        let scroll = window.pageYOffset

        if(scroll == 0 && _.$header.classList.contains('is-sticky')) {
            _.$header.classList.remove('is-sticky')
        } else if(scroll > 0 && !_.$header.classList.contains('is-sticky')) {
            _.$header.classList.add('is-sticky')
        }
    }

    initPopin() {
        const _ = this

        _.$popins = document.querySelectorAll('.js-popin')
        _.$popinOpen = document.querySelectorAll('[data-popin-open]')
        _.$popinClose = document.querySelectorAll('.js-popin-close')
        _.popinActive = 0

        _.$popinOpen.forEach(($btn) => {
            let popup = document.getElementById($btn.dataset.popinOpen)
            
            $btn.addEventListener('click', () => {
                
                _.opts.onPopupOpen(popup)

                popup.classList.add('is-open')
                _.popinActive += 1

                if(_.popinActive == 1) {
                    config.$body.classList.add('popin-open')
                    config.$html.style.height = `${config.W.h}px`
                }


            })
        })

        _.$popinClose.forEach(($btn) => {
            let popup = $btn.closest('.js-popin')
            $btn.addEventListener('click', () => {

                _.opts.onPopupClose(popup)

                popup.classList.remove('is-open')
                _.popinActive -= 1

                if(_.popinActive == 0) {
                    console.log('scroll')
                    config.$body.classList.remove('popin-open')
                    config.$html.removeAttribute('style')
                }
            })
        })

        _.scroll()
    }
}
