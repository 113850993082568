import config from '../config'
import Plyr from 'plyr'


export default class MainVideo {

    constructor(){
        const _ = this
        // console.log('MainVideo:constructor')

        _.$video = document.querySelector('.js-main-video')

        if(_.$video) {
            _.plyr = new Plyr(_.$video, {})
        }
    }
}
