import Default from './pages/default'
import Home from './pages/home'
import Gallery from './pages/gallery'
import Carreer from './pages/carreer'
import Hotel from './pages/hotel'
import Eric from './pages/eric'
import Terms from './pages/terms'
import City from './pages/city'
import Faq from './pages/faq'
import Offers from './pages/offers'
import LongStays from './pages/long-stays'


const pages = {
    default : Default,
    home : Home,
    city : City,
    terms : Terms,
    eric : Eric,
    carreer : Carreer,
    hotel : Hotel,
    gallery : Gallery,
    faq : Faq,
    offers : Offers,
    longstays : LongStays
}

export default pages
