import config from '../config';
import Default from './default'

import Slider from '../components/slider'

export default class Eric extends Default {

    constructor() {
        super()
        const _ = this;

        _.slider = new Slider('.js-slider-inspiration', {
            flickity: {
                prevNextButtons: false,
                watchCSS: true
            }
        })
    }
}
