import config from '../config';
import Default from './default'

import { inputLabel } from '../utils';
import Accordion from '../components/accordion'

import SlimSelect from 'slim-select'

export default class Carreer extends Default {

    constructor() {
        super()
        const _ = this;

        // Accordions
        const $accordions = document.querySelectorAll('.js-accordion')

        $accordions.forEach(function(el) {
            new Accordion(el)
        })

        _.$form = document.querySelectorAll('js-form')

        // Input label
        const $inputs = Array.from(document.querySelectorAll('input'))
        $inputs.push(document.querySelector('textarea'))
        $inputs.forEach(function(input) {
            if (input) {
                input.addEventListener('focusout', inputLabel)
            }
        })

        // Job select
        _.$jobSelect = document.querySelector('.js-job-select')
        _.jobSelect = new SlimSelect({
            select: _.$jobSelect,
            placeholder: _.$jobSelect.dataset.placeholder,
            showSearch: false,
            showContent: 'down'
        })

        // File input
        _.$file = document.querySelector('.js-file')
        _.$fileInput = _.$file.querySelector('.js-file-input')
        _.$fileText = _.$file.querySelector('.js-file-text')
        _.$fileRemove = _.$file.querySelector('.js-file-remove')
        const filePlaceholder = _.$fileText.innerHTML

        let filename;
        _.$fileInput.addEventListener('change', function() {
            filename = _.$fileInput.value.split(/(\\|\/)/g).pop()
            console.log('change', _.$fileInput.value)

            if(filename !== '') {
                _.$fileInput.classList.add('is-filled')
                _.$fileText.innerHTML = filename
            }
            else {
                _.$fileInput.classList.remove('is-filled')
                _.$fileText.innerHTML = filePlaceholder
            }
        });

        _.$fileRemove.addEventListener('click', function(e) {
            e.preventDefault()
            console.log( _.$fileInput.value)
            _.$fileInput.value = ''
            _.$fileInput.dispatchEvent(new Event('change'))
        })

        // Apply button
        const $applyBtns = document.querySelectorAll('.js-job-apply')
        let jobRef
        $applyBtns.forEach(function($btn) {
            $btn.addEventListener('click', function() {
                jobRef = $btn.dataset.job
                _.jobSelect.set(jobRef)

                TweenLite.to(document.documentElement, 0.6, {
                    scrollTo: {
                        y: $target.offsetTop,
                        autoKill: false
                    },
                    ease: Expo.easeInOut
                })
            })
        })
    }
}
