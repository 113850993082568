'use strict';

import $ from 'jquery'
import config from '../config'

// Private methods
const revealEnd = Symbol('revealEnd');
const resizeUpdate = Symbol('resizeUpdate');
const debounce = Symbol('debounce');
const addEndEvent = Symbol('addEndEvent');
const whichTransitionEvent = Symbol('whichTransitionEvent');
const whichRequestAnimationFrame = Symbol('whichRequestAnimationFrame');


export default class Reveal {

    /*
     * Global Reveal
     * @param  {string} selector Links to the content to load
     * @param  {object} options  An object containing all the options
     * @return {object}          The instance's object
     */
    constructor(selector, options) {
        const _ = this;
        // console.log('Reveal:init');


        if (!(_ instanceof Reveal)) return new Reveal(selector, options);

        const raf = _[whichRequestAnimationFrame]();
        _.transitionEnd = _[whichTransitionEvent]() ? _[whichTransitionEvent]() : 'transitionsEnd';

        _.opts = {
            revealClass: 'is-visible',
            offsetIn: 5,
            offsetOut: 0,
            forward: true
        }

        // Defaults options
        _.setOpts(options);

        _.selector = selector;
        _.$items = $(selector);
        _.isActive = false;

        // Position vars
        _.lastPos = -1;
        _.newPos = window.pageYOffset;

        _.opts.offsetIn = (100 - _.opts.offsetIn) / 100;
        _.opts.offsetOut = (100 - _.opts.offsetOut) / 100;


        // Set all the items
        _.set();

        // Throttle the window resize event
        $(window).on('resize', _[addEndEvent]());


        /**
         * The loop which check for items to reveal
         * @return {undefined}
         */
        function loop() {
            // If reveal is disabled, stop here
            if (!_.isActive) return;

            if (!_.opts.forward) _.pos();

            // Update the pageYOffset
            _.newPos = window.pageYOffset;

            // If position have not changed,
            // stop here and restart
            if (_.lastPos == _.newPos) {
                raf(loop);
                return;
            } else {
                _.lastPos = _.newPos;
            }


            // Check for items in view
            _.$items.each(function (i, el) {
                if (_.newPos + config.W.h * _.opts.offsetIn > el._top && _.newPos < el._top + el._height && el._isHidden) {
                    _.reveal(el);
                }
                if (!_.opts.forward && (_.newPos + config.W.h * _.opts.offsetOut < el._top || _.newPos > el._top + el._height) && !el._isHidden) {
                    _.hide(el);
                }
            });

            raf(loop);
        }

        _.loop = loop;

        /**
         * Enable/init the loop
         * @return {object} The instance's object
         */
        _.enable();
        $(window).on('resizeEnd', {
            instance: _
        }, _[resizeUpdate]);


        return _;
    }

    getOpts() {
        const _ = this;
        // console.log('Reveal:getOpts', _.opts);
        return _.opts;
    }

    setOpts(options) {
        const _ = this;
        // console.log('Reveal:setOpts');
        $.extend(true, _.opts, options);
    }


    /**
     * Enable and launch the loop
     * @return {[type]} [description]
     */
    enable() {
        const _ = this;
        // console.log('Reveal:enable');
        _.lastPos = -1;
        _.isActive = _.$items.length ? true : false;
        if (_.isActive) _.loop();
        return _;
    }


    /**
     * Disable the loop
     * @return {object} The instance's object
     */
    disable() {
        const _ = this;
        // console.log('Reveal:disable');
        _.isActive = false;
        $(window).off('resizeEnd', _[resizeUpdate]);
        return _;
    }


    /**
     * Set the reveal items
     * @param  {object} $items The items to set
     * @return {object}        The instance's object
     */
    set($items, selector) {
        const _ = this;
        // console.log('Reveal:set');

        // Set all the items if no
        // argument has been passed
        $items = $items || _.$items;

        // Use the default selector if
        // no argument has been passed
        selector = selector || _.selector;

        // Get all needed variables
        // and save them on the element
        $items.each(function (i, el) {
            let $this = $(this);
            this._isHidden = true;
            this._delay = $this.data('delay') || 0;
            this._selector = selector;
            _.pos();
        });

        return _;
    }


    /**
     * Set the reveal items position
     * @param  {object} $items The items to set
     * @return {object}        The instance's object
     */
    pos($items, selector) {
        const _ = this;
        // console.log('Reveal:pos');

        // Set all the items if no
        // argument has been passed
        $items = $items || _.$items;

        // Use the default selector if
        // no argument has been passed
        selector = selector || _.selector;

        // Get all needed variables
        // and save them on the element
        $items.each(function (i, el) {
            let $this = $(this);
            this._top = Math.round($this.offset().top);
            this._height = $this.outerHeight();
        });

        return _;
    }


    /**
     * Update the reveal items
     * @param  {object} $items The items to update
     * @return {object}        The instance's object
     */
    update($items) {
        const _ = this;
        // console.log('Reveal:update');

        // Update the window innerHeight
        config.W.h = window.innerHeight;

        // Set all the items if no
        // argument has been passed
        $items = $items || _.$items;

        // Update all needed variables
        // and save them on the element
        $items.each(function (i, el) {
            var $this = $(this);
            this._top = Math.round($this.offset().top);
            this._height = $this.outerHeight();
        });

        _.enable();

        return _;
    }


    /**
     * Add items to the reveal
     * @param {string} selector A selector for the new items
     * @return {object} The instance's object
     */
    add(selector) {
        const _ = this;
        // console.log('Reveal:add');

        // Use the default selector if
        // no argument has been passed
        selector = selector || slctr;

        var $newItems = config.$document.find(selector);

        // Set the new items
        _.set($newItems, selector);

        // Add the new items
        _.$items = _.$items.add($newItems);

        // Re-enable the loop if
        // it has been stopped
        _.enable();

        return _;
    }


    /**
     * Reveal an item
     * @param  {object} item The item to reveal
     * @return {object}      The instance's object
     */
    reveal(item) {
        const _ = this;
        // console.log('Reveal:reveal', item);

        var $item = $(item);
        item._isHidden = false;

        // If style if to be forwarded
        if (_.opts.forward) {
            $item.on(_.transitionEnd, {
                item: item,
                instance: _
            }, _[revealEnd]);

            setTimeout(function () {
                $item.addClass(_.opts.revealClass).trigger('reveal:reveal');
                // If `transitionend` is not supported, trigger it via jQuery
                if (!_.transitionEnd) $item.trigger('transitionend');
            }, item._delay * 1000);
        } else {
            $item.addClass(_.opts.revealClass);
        }
    }


    /**
     * Hide an item
     * @param  {object} item The item to hide
     * @return {object}      The instance's object
     */
    hide(item) {
        const _ = this;
        // console.log('Reveal:hide', item);

        var $item = $(item);
        item._isHidden = true;

        $item.removeClass(_.opts.revealClass);
    }


    /* ================================
     * Private methods
     * ================================ */

    /**
     * Stuff to do when an item has been revealed
     * @param  {object} e The `transitionEnd` event's object
     * @return {undefined}
     */
    [revealEnd](e) {
        var _ = e.data.instance;
        var item = e.data.item;
        var $item = $(item);

        setTimeout(function () {

            // Remove the classes on the item and unbind the transition event
            // $item.removeClass(item._selector.substring(1) + ' ' + _.opts.revealClass).off(_.transitionEnd, revealEnd);
            // Remove the item from the global object
            _.$items = _.$items.not(item);
            // If no items left, disable reveal
            if (_.$items.length <= 0) _.disable();

        }, 1000);
    }

    /**
     * Stuff to do on window resize
     * @return {undefined}
     */
    [resizeUpdate](e) {
        var _ = e.data.instance;
        _.update(_.$items);
    }

    /**
     * Returns a function, that, as long as it continues to be invoked, will not
     * be triggered. The function will be called after it stops being called for
     * N milliseconds. If `immediate` is passed, trigger the function on the
     * leading edge, instead of the trailing.
     * @param  {number}    wait       Timer
     * @param  {boolean}   immediate  Launch the function immediately
     * @param  {function}  func       The function that needs debounce
     * @return {function}             A function to bind to the event debounced
     */
    [debounce](wait, immediate, func) {
        var timeout;

        return function () {
            var context = this,
                args = arguments;
            var later = function () {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };
            var callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        };
    }

    /**
     * Create an ending event for the event triggered
     * @param  {object} e The triggered event's object
     * @return {undefined}
     */
    [addEndEvent]() {
        const _ = this;
        return _[debounce](200, false, function (e) {
            $(this).trigger(e.type + 'End');
        });
    }

    /**
     * Use the correct `transitionend` event
     * @return {string} The prefixed event name
     */
    [whichTransitionEvent]() {
        let t;
        let el = document.createElement('div');
        let transitions = {
            'transition': 'transitionend',
            'OTransition': 'oTransitionEnd',
            'MozTransition': 'transitionend',
            'WebkitTransition': 'webkitTransitionEnd'
        };

        for (t in transitions) {
            if (el.style[t] !== undefined) {
                return transitions[t];
            }
        }
    }

    /**
     * Use the correct `requestAnimationFrame` function
     * @return {function} The prefixed (or not) function
     */
    [whichRequestAnimationFrame]() {
        return window.requestAnimationFrame || window.webkitRequestAnimationFrame || window.mozRequestAnimationFrame || function (callback) {
            window.setTimeout(callback, 1000 / 60);
        };
    }

}


// 'use strict';
// import $ from 'jquery'

// // Private methods
// const revealEnd = Symbol('revealEnd');
// const resizeUpdate = Symbol('resizeUpdate');
// const debounce = Symbol('debounce');
// const addEndEvent = Symbol('addEndEvent');
// const whichTransitionEvent = Symbol('whichTransitionEvent');
// const whichRequestAnimationFrame = Symbol('whichRequestAnimationFrame');

// // Set global variables
// var newPos;
// var $document = $(document);
// var $window = $(window);
// var lastPos = -1;
// var newPos = window.pageYOffset;
// var viewHeight = window.innerHeight;
// var opts;

// export default class Reveal {

//     /*
//      * Global Reveal
//      * @param  {string} selector Links to the content to load
//      * @param  {object} options  An object containing all the options
//      * @return {object}          The instance's object
//      */
//     constructor(selector, options) {
//         console.log('reveal');

//         if (!(this instanceof Reveal)) return new Reveal(selector, options);
//         var _ = this;

//         var raf = _[whichRequestAnimationFrame]();
//         _.transitionEnd = _[whichTransitionEvent]() ? _[whichTransitionEvent]() : 'transitionsEnd';


//         // Defaults options
//         _.setOpts(options);

//         _.selector = selector;
//         _.$items = $(selector);
//         _.isActive = false;

//         // Set all the items
//         _.set();

//         // Throttle the window resize event
//         $window.on('resize', _[addEndEvent]());


//         /**
//          * The loop which check for items to reveal
//          * @return {undefined}
//          */
//         function loop() {
//             // console.log('reveal:loop');
//             // If reveal is disabled, stop here
//             if (!_.isActive) return;

//             // Update the pageYOffset
//             newPos = window.pageYOffset;

//             // If position have not changed,
//             // stop here and restart
//             if (lastPos == newPos) {
//                 raf(loop);
//                 return;
//             } else {
//                 lastPos = newPos;
//             }


//             // Check for items in view
//             _.$items.each(function(i, el) {
//                 if (newPos + viewHeight * 0.95 > el._top && newPos < el._top + el._height && el._isHidden) {
//                     _.reveal(el);
//                 }
//             });

//             raf(loop);
//         }

//         _.loop = loop;

//         /**
//          * Enable/init the loop
//          * @return {object} The instance's object
//          */
//         _.enable();
//         $window.on('resizeEnd', { instance: _ }, _[resizeUpdate]);

//         return _;
//     }

//     getOpts() {
//         console.log(opts);
//         return opts;
//     }

//     setOpts(options) {
//         var _ = this;
//         $.extend(true, _.options, options);
//         opts = _.options;
//     }


//     /**
//      * Enable and launch the loop
//      * @return {[type]} [description]
//      */
//     enable() {
//         // console.log('reveal:enable');
//         var _ = this;
//         lastPos = -1;
//         _.isActive = _.$items.length ? true : false;
//         if (_.isActive) _.loop();
//         return _;
//     }


//     /**
//      * Disable the loop
//      * @return {object} The instance's object
//      */
//     disable() {
//         // console.log('reveal:disable');
//         var _ = this;
//         _.isActive = false;
//         $window.off('resizeEnd', _[resizeUpdate]);
//         return _;
//     }



//     /**
//      * Set the reveal items
//      * @param  {object} $items The items to set
//      * @return {object}        The instance's object
//      */
//     set($items, selector) {
//         // console.log('reveal:set');
//         var _ = this;

//         // Set all the items if no
//         // argument has been passed
//         $items = $items || _.$items;

//         // Use the default selector if
//         // no argument has been passed
//         selector = selector || _.selector;

//         // Get all needed variables
//         // and save them on the element
//         $items.each(function(i, el) {
//             var $this = $(this);
//             this._top = Math.round($this.offset().top);
//             this._height = $this.outerHeight();
//             this._isHidden = true;
//             this._delay = $this.data('delay') || 0;
//             this._selector = selector;
//         });

//         return _;
//     }



//     /**
//      * Update the reveal items
//      * @param  {object} $items The items to update
//      * @return {object}        The instance's object
//      */
//     update($items) {
//         // console.log('reveal:update');
//         var _ = this;

//         // Update the window innerHeight
//         viewHeight = window.innerHeight;

//         // Set all the items if no
//         // argument has been passed
//         $items = $items || _.$items;

//         // Update all needed variables
//         // and save them on the element
//         $items.each(function(i, el) {
//             var $this = $(this);
//             this._top = Math.round($this.offset().top);
//             this._height = $this.outerHeight();
//         });

//         _.enable();

//         return _;
//     }



//     /**
//      * Add items to the reveal
//      * @param {string} selector A selector for the new items
//      * @return {object} The instance's object
//      */
//     add(selector) {
//         // console.log('reveal:add');
//         var _ = this;

//         // Use the default selector if
//         // no argument has been passed
//         selector = selector || slctr;

//         var $newItems = $document.find(selector);

//         // Set the new items
//         _.set($newItems, selector);

//         // Add the new items
//         _.$items = _.$items.add($newItems);

//         // Re-enable the loop if
//         // it has been stopped
//         _.enable();

//         return _;
//     }



//     /**
//      * Reveal an item
//      * @param  {object} item The item to reveal
//      * @return {object}      The instance's object
//      */
//     reveal(item) {
//         // console.log('reveal:reveal', item);
//         var _ = this;

//         var $item = $(item);
//         item._isHidden = false;

//         $item.on(_.transitionEnd, { item: item, instance: _ }, revealEnd);

//         setTimeout(function() {
//             $item.addClass(_.options.revealClass).trigger('reveal:reveal');
//             // If `transitionend` is not supported, trigger it via jQuery
//             if (!_.transitionEnd) $item.trigger('transitionend');
//         }, item._delay*1000);
//     }


//     /* ================================
//      * Private methods
//      * ================================ */

//     /**
//      * Stuff to do when an item has been revealed
//      * @param  {object} e The `transitionEnd` event's object
//      * @return {undefined}
//      */
//     [revealEnd](e) {
//         var _ = e.data.instance;
//         var item = e.data.item;
//         var $item = $(item);

//         setTimeout(function() {

//             // Remove the classes on the item and unbind the transition event
//             $item.removeClass(item._selector.substring(1) + ' ' + _.options.revealClass).off(transitionEnd, revealEnd);
//             // Remove the item from the global object
//             _.$items = _.$items.not(item);
//             // If no items left, disable reveal
//             if (_.$items.length <= 0) _.disable();

//         }, 1000);
//     }

//     /**
//      * Stuff to do on window resize
//      * @return {undefined}
//      */
//     [resizeUpdate](e) {
//         var _ = e.data.instance;
//         _.update(_.$items);
//     }

//     /**
//      * Returns a function, that, as long as it continues to be invoked, will not
//      * be triggered. The function will be called after it stops being called for
//      * N milliseconds. If `immediate` is passed, trigger the function on the
//      * leading edge, instead of the trailing.
//      * @param  {number}    wait       Timer
//      * @param  {boolean}   immediate  Launch the function immediately
//      * @param  {function}  func       The function that needs debounce
//      * @return {function}             A function to bind to the event debounced
//      */
//     [debounce](wait, immediate, func) {
//         var timeout;

//         return function() {
//             var context = this, args = arguments;
//             var later = function() {
//                 timeout = null;
//                 if (!immediate) func.apply(context, args);
//             };
//             var callNow = immediate && !timeout;
//             clearTimeout(timeout);
//             timeout = setTimeout(later, wait);
//             if (callNow) func.apply(context, args);
//         };
//     }

//     /**
//      * Create an ending event for the event triggered
//      * @param  {object} e The triggered event's object
//      * @return {undefined}
//      */
//     [addEndEvent]() {
//         var _ = this;
//         return _[debounce](200, false, function(e) {
//             $(this).trigger(e.type + 'End');
//         });
//     }

//     /**
//      * Use the correct `transitionend` event
//      * @return {string} The prefixed event name
//      */
//     [whichTransitionEvent]() {
//         var t;
//         var el = document.createElement('div');
//         var transitions = {
//             'transition':'transitionend',
//             'OTransition':'oTransitionEnd',
//             'MozTransition':'transitionend',
//             'WebkitTransition':'webkitTransitionEnd'
//         };

//         for(t in transitions){
//             if( el.style[t] !== undefined ){
//                 return transitions[t];
//             }
//         }
//     }

//     /**
//      * Use the correct `requestAnimationFrame` function
//      * @return {function} The prefixed (or not) function
//      */
//     [whichRequestAnimationFrame]() {
//         return window.requestAnimationFrame || window.webkitRequestAnimationFrame || window.mozRequestAnimationFrame || function(callback) { window.setTimeout(callback, 1000 / 60); };
//     }

// }
